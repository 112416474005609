import { useLayoutEffect, useState } from 'react'
import { HeightWidth } from '../models/redux-models'

export function ImageStorageUrl(image_url: any) {
  return process.env.REACT_APP_ANTMALL_STORAGE_URL + '/' + image_url
}

export function CreateMarkup(text: any) {
  return { __html: text }
}

export function DangerouslySetInnerHTML(text: any) {
  return <div dangerouslySetInnerHTML={CreateMarkup(text)} />
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height }: any = window
  return {
    width,
    height,
  }
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState<HeightWidth>(
    getWindowDimensions(),
  )

  useLayoutEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
