import {
  pnsMnStateModel,
  SiteSettings,
  SettingsField,
  News,
} from "../models/redux-models";
import Layout from "../Layouts/MainLayout";
import "../App.css";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { fetchPage, fetchNews } from "../store/Actions";
import { useEffect } from "react";
import { BrowserRouter as Router, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import NewsBody from "../components/NewsBody";
import SectionTitle from "../components/SectionTitle";
import NewsEvent from "../components/NewsEvents";

interface SiteSettingsProps {
  SiteSettings: SiteSettings;
}
function NewsComponent({ SiteSettings }: SiteSettingsProps) {
  let { slug } = useParams();

  const news: any = useAppSelector(
    (state: pnsMnStateModel) => state.pnsMn?.news
  );
  const newsDetail: any = useAppSelector(
    (state: pnsMnStateModel) => state.pnsMn?.newsDetail
  );
  const allNews: any = useAppSelector(
    (state: pnsMnStateModel) => state.pnsMn?.all_news
  );
  const sectionTitles: any = useAppSelector(
    (state: pnsMnStateModel) => state?.pnsMn?.section_titles
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchNews(slug));
  }, [dispatch, slug]);

  // if(!slug || newsDetail){
  //   return <Loader />
  // }

  return (
    <Layout>
      {SiteSettings ? (
        <PagesHeader
          title={"МЭДЭЭ, МЭДЭЭЛЭЛ"}
          slug={""}
          pageheader_background_image={
            SiteSettings?.pageheader_background_image
          }
        />
      ) : (
        <></>
      )}
      {slug && slug.length > 0 && newsDetail ? (
        <>

          <NewsBody newsDetail={newsDetail} />
          <section className="content-inner">
            <SectionTitle section={sectionTitles?.homepage_news_section} />
            <NewsEvent pnsNews={news} />
          </section>
        </>
      ) : (
        <>
          {allNews && allNews.length > 0 ? (
            <section className="content-inner-1">
              <div className="container">
                <div className="row">
                  {allNews.map((news: News, indx: number) => (
                    <div className="col-md-6 col-lg-12 m-b50" key={indx}>
                      <div className="dlab-card blog-half">
                        {indx % 2 === 0 ? (
                          <>
                            <div
                              className="dlab-media wow fadeIn"
                              data-wow-delay="0.2s"
                              data-wow-duration="2s"
                              style={{
                                visibility: "visible",
                                animationDuration: "2s",
                                animationDelay: "0.2s",
                                animationName: "fadeIn",
                              }}
                            >
                              <img
                                src={"https://api.pns.mn/storage/" + news.image}
                                alt={news.title}
                              />
                            </div>
                            <div className="dlab-info">
                              <h3 className="dlab-title">
                                <a href={"/news/" + news.slug}>{news.title}</a>
                              </h3>
                              <p>{news.excerpt}</p>
                              <div className="dlab-readmore">
                                <a
                                  href={"/news/" + news.slug}
                                  className="readmore"
                                >
                                  Дэлгэрэнгүй
                                </a>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="dlab-info">
                              <h3 className="dlab-title">
                                <a href={"/news/" + news.slug}>{news.title}</a>
                              </h3>
                              <p>{news.excerpt}</p>
                              <div className="dlab-readmore">
                                <a
                                  href={"/news/" + news.slug}
                                  className="readmore"
                                >
                                  Дэлгэрэнгүй
                                </a>
                              </div>
                            </div>
                            <div
                              className="dlab-media wow fadeIn"
                              data-wow-delay="0.2s"
                              data-wow-duration="2s"
                              style={{
                                visibility: "visible",
                                animationDuration: "2s",
                                animationDelay: "0.2s",
                                animationName: "fadeIn",
                              }}
                            >
                              <img
                                src={"https://api.pns.mn/storage/" + news.image}
                                alt={news.title}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className="page-item disabled">
                      <a
                        className="page-link"
                        href="#"
                        tabIndex={-1}
                        aria-disabled="true"
                      >
                        Previous
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        1
                      </a>
                    </li>

                    <li className="page-item">
                      <a className="page-link" href="#">
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </section>
          ) : (
            <Loader />
          )}
        </>
      )}
    </Layout>
  );
}

export default NewsComponent;

interface PageHeader {
  title?: string;
  slug: string;
  pageheader_background_image: SettingsField;
}
export function PagesHeader({
  title,
  slug,
  pageheader_background_image,
}: PageHeader) {
  return (
    <div className="dlab-bnr-inr">
      <div className="container">
        <div className="dlab-bnr-inr-entry">
          <h1> {title ? title : "Мэдээ, Мэдээлэл"}</h1>
        </div>
      </div>
    </div>
  );
}
