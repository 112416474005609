import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { pnsMnArrayModel, Data } from "../models/redux-models"
 
const initialpnsMnState: any = {
  elements: [],
  other: [],
  page: [],
  navigation: []
};

const pnsMnSlice = createSlice({
  name: "pnsMnData",
  initialState: initialpnsMnState,
  reducers: {
    setpnsMn(state, action: PayloadAction<Data>) {
      state.elements = action.payload.data.elements;
      state.sliders = action.payload.data.sliders;
      state.navigation = action.payload.data.navigation;
      state.services = action.payload.data.services;
      state.news = action.payload.data.news;
      state.site_settings = action.payload.data.site_settings;
      state.section_titles = action.payload.data.section_titles;
      state.contacts = action.payload.data.contacts;
      state.social_links = action.payload.data.social_links;
      state.all_news = action.payload.data.all_news;       
      state.faqs = action.payload.data.faqs;
      state.homepage_section_aboutus = action.payload.data.homepage_section_aboutus;    
      state.order_form = action.payload.data.order_form;          
    },
    setAntPayPage(state, action: PayloadAction<Data>) {
      state.page = action.payload.data;
    },
    setNewsDetail(state, action: PayloadAction<any>) {
      state.newsDetail = action.payload.data;
    },    
  },
});
export default pnsMnSlice;
