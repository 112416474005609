import Logo from '../Logo.png'
import { Outlet, Link } from 'react-router-dom'
import {
  Contacts,
  Navigation,
  SiteSettings,
  SocialLinks,
} from '../models/redux-models'
import ContactInfo from './ContactInfo'
import SocialLinksComponent from './SocialLinksComponent'

interface NavigationProps {
  navigation?: Navigation[]
  contacts: Contacts[]
  socialLinks: SocialLinks[]
  SiteSettings: SiteSettings
}

function Footer({
  navigation,
  contacts,
  socialLinks,
  SiteSettings,
}: NavigationProps) {
  return (
    <footer
      className="site-footer"
      id="footer"
      style={{ display: 'block', height: '391.594px', marginTop: '20px' }}
    >
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 wow fadeInLeft"
              data-wow-duration="2s"
              data-wow-delay="0.3s"
              style={{
                visibility: 'visible',
                animationDuration: '2s',
                animationDelay: '0.3s',
                animationName: 'fadeInLeft',
              }}
            >
              <div className="widget widget_about">
                <div className="footer-logo">
                  <a href="/">
                    {SiteSettings?.footer_logo?.value && (
                      <img
                        src={'https://api.pns.mn/storage/' + SiteSettings?.footer_logo?.value}
                        alt="pns.mn"
                      />
                    )}
                  </a>
                </div>
                <span
                  style={{
                    maxWidth: 440,
                    display: 'block',
                    marginBottom: 20,
                  }}
                >
                  Professional nanny agency нь эцэг эхчүүдэд зориулж асрагчдын
                  мэдээллийг бүрэн судалж туршлагтай мэргэжлийн хүүхэд асрагчийг
                  бэлддэг байгууллага юм.
                </span>
                <SocialLinksComponent
                  type="footer"
                  socialLinks={socialLinks}
                  className={'dlab-social-icon'}
                />
              </div>
            </div>
            <div
              className="col-md-6 wow fadeInLeft"
              data-wow-duration="2s"
              data-wow-delay="0.6s"
              style={{
                visibility: 'visible',
                animationDuration: '2s',
                animationDelay: '0.6s',
                animationName: 'fadeInLeft',
              }}
            >
              <div className="widget">
                <h5 className="footer-title">Холбоо барих</h5>

                <ContactInfo
                  contacts={contacts}
                  className={'contact-info-bx'}
                  type={'footer'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer bottom part */}
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 text-md-left text-center">
              <span>© 2022 pns.mn. All Right Reserved</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
