import axios from "axios";

export function Api() {
  return axios.create({
    baseURL: "https://api.pns.mn/api/",
  });
}

export async function createOrder(data: any) {
  const response: any = await Api().post("/create-order", {
    ...data,
  });
  return response.data;
}

export default {
  async getAllData() {
    const response: any = await Api().get("/index");
    return response.data;
  },
  async GetPage(slug?: string) {
    const response: any = await Api().get(`/page/${slug}`);
    return response;
  },
  async GetNews(slug?: string) {
    const response: any = await Api().get(`/news/${slug}`);
    return response;
  },
};
