import { HeightWidth, pnsMnStateModel } from "../models/redux-models";
import Layout from "../Layouts/MainLayout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { fetchpnsMns } from "../store/Actions";
import CompService from "../components/CompService";
import HomeAboutUs from "../components/AboutUsHome";
import NewsEvent from "../components/NewsEvents";
import Loader from "../components/Loader";
import HeroSlider from "../components/HeroSlider";
import SectionTitle from "../components/SectionTitle";
import { useWindowDimensions } from "../Helper/Helpers";
function Index() {
  const [load, setLoad] = useState<boolean>(true);
  const { height, width }: HeightWidth = useWindowDimensions();

  const dispatch = useAppDispatch();

  const pnsServices: any = useAppSelector(
    (state: pnsMnStateModel) => state?.pnsMn?.services
  );
  const sectionTitles: any = useAppSelector(
    (state: pnsMnStateModel) => state?.pnsMn?.section_titles
  );

  const pnsNews: any = useAppSelector(
    (state: pnsMnStateModel) => state?.pnsMn?.news
  );
  const sliders: any = useAppSelector(
    (state: pnsMnStateModel) => state.pnsMn?.sliders
  );
  // const contacts: any = useAppSelector(
  //   (state: pnsMnStateModel) => state.pnsMn?.contacts,
  // )
  const homepageAboutUs: any = useAppSelector(
    (state: pnsMnStateModel) => state.pnsMn?.homepage_section_aboutus
  );

  useEffect(() => {
    dispatch(fetchpnsMns());
    setLoad(false);
  }, [dispatch]);

  if (load) {
    return <Loader />;
  }

  return (
    <Layout>
      {sliders ? <HeroSlider Sliders={sliders} /> : <Loader />}
      {pnsServices ? (
        <section className="content-inner-2" id="ourServices">
          <SectionTitle
            section={sectionTitles?.homepage_service_section}
            titleColor={"#2a489f"}
          />
          <CompService
            services={pnsServices}
            heightWidth={{
              height,
              width,
            }}
          />
        </section>
      ) : (
        <Loader />
      )}
      {
        <section className="content-inner exhibition-bx">
          <HomeAboutUs homepageAboutUs={homepageAboutUs} />
        </section>
      }

      {pnsNews ? (
        <section className="content-inner">
          <SectionTitle section={sectionTitles?.homepage_news_section} />
          <NewsEvent pnsNews={pnsNews} />
        </section>
      ) : (
        <Loader />
      )}
    </Layout>
  );
}

export default Index;
