import { Oval } from 'react-loader-spinner';

function Loader() {
  return (
    <div style={{
        margin: '0 auto',
        padding: '46px',
        width: '180px',
        height: '180px',
        textAlign: 'center'
    }}>
        <Oval ariaLabel="loading-indicator" color="#2a489f" />
    </div>
  );
}

export default Loader;
