import logger from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";
import pnsMnSlice from "./Slice";
let rootReducer: any = { pnsMn: pnsMnSlice.reducer };
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      // prepend and concat calls can be chained
      .concat(logger),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
