import { News } from "../models/redux-models";
import { DangerouslySetInnerHTML } from "../Helper/Helpers";
import { Helmet, HelmetProvider } from "react-helmet-async";

interface PageBodyProps {
  newsDetail: News;
}
function NewsBody({ newsDetail }: PageBodyProps) {
  return (
    <>
      <Helmet>
        <title>{newsDetail?.title}</title>
        <link rel="canonical" href={"https://www.pns.mn/" + newsDetail.slug} />

        <meta name="description" content={newsDetail?.body} />
        <meta
          property="og:image"
          content={
            newsDetail?.image &&
            "https://api.pns.mn/storage/" + newsDetail?.image
          }
        />
      </Helmet>

      <section className="content-inner">
        <div className="container">
          <div
            className="social-box"
            style={{
              maxWidth: 580,
              display: "flex",
              margin: "0 auto",
              alignContent: "center",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <div
              className="fb-share-button"
              data-layout="button"
              data-size="small"
              data-mobile-iframe="true"
            >
              <a
                className="btn btn-outline-primary btn-sm"
                target="_blank"
                href={
                  "https://www.facebook.com/sharer/sharer.php?u=https://www.pns.mn/" +
                  newsDetail.slug
                }
                style={{
                  color: "#4267B2",
                  border: "1px solid #4267B2",
                  padding: "6px 30px",
                  paddingTop: "10px",
                  marginRight: 20,
                }}
              >
                <i
                  style={{
                    fontSize: 18,
                  }}
                  className="fa fa-facebook"
                />
              </a>
            </div>

            <div
              className="fb-twitter-button"
              data-layout="button"
              data-size="small"
              data-mobile-iframe="true"
            >
              <a
                style={{
                  color: "#00ACEE",
                  border: "1px solid #00ACEE",
                  padding: "6px 30px",
                  paddingTop: "10px",
                }}
                className="btn btn-outline-info btn-sm"
                target="_blank"
                href={
                  "https://twitter.com/intent/tweet?url=https://www.pns.mn/" +
                  newsDetail.slug
                }
              >
                <i
                  style={{
                    fontSize: 18,
                  }}
                  className="fa fa-twitter"
                />
              </a>
            </div>
          </div>

          <div className="blog-post blog-single">
            <div className="dlab-info">
              <h2 className="dlab-title">
                <span
                  style={{
                    maxWidth: 580,
                    display: "block",
                    margin: "0 auto",
                    fontSize: "32px",
                  }}
                >
                  {newsDetail?.title}
                </span>
              </h2>
              <div className="dlab-media">
                {newsDetail?.image && (
                  <a href="#">
                    <img
                      src={"https://api.pns.mn/storage/" + newsDetail?.image}
                      alt={newsDetail?.title}
                    />
                  </a>
                )}
              </div>
              <div className="dlab-post-text text newsDetail">
                {DangerouslySetInnerHTML(newsDetail?.body)}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewsBody;
