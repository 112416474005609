import {
  pnsMnStateModel,
  SiteSettings,
  SettingsField,
  Services,
  ServiceElements,
  HeightWidth,
} from '../models/redux-models'
import Layout from '../Layouts/MainLayout'
import '../App.css'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks'
import { useEffect, useState } from 'react'
import {
  DangerouslySetInnerHTML,
  ImageStorageUrl,
  useWindowDimensions,
} from '../Helper/Helpers'
import { BrowserRouter as Router, useParams } from 'react-router-dom'
import Loader from '../components/Loader'
import { PagesHeader } from './News'
import CompService from '../components/CompService'

interface SiteSettingsProps {
  SiteSettings: SiteSettings
}

function ServicePage({ SiteSettings }: SiteSettingsProps) {
  let { id }: any = useParams()
  const { height, width }: HeightWidth = useWindowDimensions()
  const [getService, setService] = useState<Services>()

  const pnsServices: any = useAppSelector(
    (state: pnsMnStateModel) => state?.pnsMn?.services,
  )
  const sectionTitles: any = useAppSelector(
    (state: pnsMnStateModel) => state?.pnsMn?.section_titles,
  )

  useEffect(() => {
    if (id) {
      const dta: Services =
        pnsServices &&
        pnsServices.filter(
          (service: Services) => Number(service.id) === Number(id),
        )[0]
      setService(dta)
    }
  }, [id, pnsServices])

  return (
    <Layout>
      {SiteSettings && (
        <PagesHeader
          title={'ПРОФЕССИОНАЛ НАННИ СЕРВИС'}
          slug={'@'}
          pageheader_background_image={
            SiteSettings?.pageheader_background_image
          }
        />
      )}
      <>
        <section
          className=""
          style={{
            marginTop: 60,
          }}
        >
          <div className="container" id={'service' + getService?.id}>
            <div className="section-head text-center">
              <h2 className="title">{getService && getService?.name}</h2>
            </div>
          </div>
        </section>
        <section className="">
          <div className="container">
            <div className="service_tables-row">
              <div className="row">
                {getService &&
                  getService?.elements &&
                  Object.values(getService?.elements).length > 0 &&
                  Object.values(getService?.elements).map(
                    (el: ServiceElements, indx: number) => (
                      <div
                        key={indx}
                        className="col-sm-12 col-md-4 col-lg-4 wow fadeInUp"
                        data-wow-delay="0.2s"
                        data-wow-duration="2s"
                        style={{
                          visibility: 'visible',
                          animationDuration: '2s',
                          animationDelay: '0.2s',
                          animationName: 'fadeInUp',
                        }}
                      >
                        <div className="service_tables-wrapper table-option m-md-b30">
                          <div className="service_tables-inner">
                            <div className="service_tables-title">
                              <h2>{el.excerpt}</h2>
                            </div>

                            {DangerouslySetInnerHTML(el.body)}
                          </div>
                        </div>
                      </div>
                    ),
                  )}
              </div>
            </div>
          </div>
        </section>
      </>
      {pnsServices ? (
        <section className="content-inner-2" id="ourServices">
          <CompService
            services={pnsServices}
            heightWidth={{
              height,
              width,
            }}
          />
        </section>
      ) : (
        <Loader />
      )}
    </Layout>
  )
}
export default ServicePage
