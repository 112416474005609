import { Page } from '../models/redux-models'
import { DangerouslySetInnerHTML } from '../Helper/Helpers'

interface PageBodyProps {
  page: Page
}
function PageBody({ page }: PageBodyProps) {
  return (
    <section className="content-inner-2">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="dlab-page-text sidebar">
              <div className="dlab-divider bg-gray-dark" />
              <div className="font-18">{DangerouslySetInnerHTML(page.body)}</div>
              <div className="dlab-divider bg-gray-dark" />
            </div>
          </div>
      
        </div>
      </div>
    </section>
  )
}

export default PageBody
