import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// import "./theme/assets/vendor/animate.css/animate.min.css";
// import "./theme/assets/vendor/aos/aos.css";
// import "./theme/assets/vendor/bootstrap/css/bootstrap.min.css";
// import "./theme/assets/vendor/bootstrap-icons/bootstrap-icons.css";
// import "./theme/assets/vendor/boxicons/css/boxicons.min.css";
// import "./theme/assets/vendor/glightbox/css/glightbox.min.css";
// import "./theme/assets/vendor/remixicon/remixicon.css";
// import "./theme/assets/vendor/swiper/swiper-bundle.min.css";
// import "./theme/assets/css/style.css";

import "./theme/pns.mn/vendor/owl-carousel/owl.carousel.css";
import "./theme/pns.mn/vendor/magnific-popup/magnific-popup.min.css";
import "./theme/pns.mn/vendor/lightgallery/css/lightgallery.min.css";
import "./theme/pns.mn/vendor/animate/animate.css";
import "./theme/pns.mn/css/style.css";
import "./theme/pns.mn/vendor/revolution/revolution/css/revolution.min.css";

import { BrowserRouter } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
