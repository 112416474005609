import { pnsMnStateModel } from '../models/redux-models'
import '../App.css'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks'
import { fetchpnsMns } from '../store/Actions'
import { Fragment, useEffect } from 'react'
import Loader from '../components/Loader'
import SocialLinksComponent from '../components/SocialLinksComponent'
import { BackTop } from 'antd';



const MainLayout = ({ children }: any) => {  
  const dispatch = useAppDispatch()
  const navigation = useAppSelector(
    (state: pnsMnStateModel) => state.pnsMn?.navigation,
  )
  const SiteSettings: any = useAppSelector(
    (state: pnsMnStateModel) => state.pnsMn?.site_settings,
  )
  const contacts: any = useAppSelector(
    (state: pnsMnStateModel) => state.pnsMn?.contacts,
  )
  const socialLinks: any = useAppSelector(
    (state: pnsMnStateModel) => state.pnsMn?.social_links,
  )

  useEffect(() => {
    dispatch(fetchpnsMns())
  }, [dispatch])

  return (
    <Fragment>
      {navigation ? <Header navigation={navigation} /> : <Loader />}
      <SocialLinksComponent
        socialLinks={socialLinks}
        className={'sidenav-list'}
      />
      <div className="page-wraper">
        <div className="page-content bg-white">{children}</div>
        <Footer
          navigation={navigation}
          contacts={contacts}
          socialLinks={socialLinks}
          SiteSettings={SiteSettings}
        />
      </div>
      <BackTop />

    </Fragment>
  )
}

export default MainLayout
