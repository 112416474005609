import { SocialLinks } from '../models/redux-models'

interface SProps {
  socialLinks: SocialLinks[]
  type?: string
  className: string
}
function SocialLinksComponent({ socialLinks, type, className }: SProps) {
  return (
    <div className={className}>
      <ul className={type === 'footer' ? 'footer_social_icons' : 'navbar'}>
        {socialLinks &&
          socialLinks.length > 0 &&
          socialLinks.map((socialLink: SocialLinks, index: any) => (
            <li key={index}>
              <a
                className={type === 'footer' ? '' : 'scroll nav-link'}
                href={socialLink.link}
              >
                <i className={socialLink.icon}></i>
                {type === 'footer' ? '' : <span>{socialLink.text}</span>}
              </a>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default SocialLinksComponent

{
  /* <li>
          <a className="scroll nav-link" href="https://www.fb.com">
            <i className="lab la-youtube"></i> <span>Youtube</span>
          </a>
        </li>
        <li>
          <a className="scroll nav-link" href="https://www.fb.com">
            <i className="las la-phone-volume"></i> <span>Холбоо барих</span>
          </a>
        </li> */
}
