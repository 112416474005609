import pnsMnSlice from './Slice'
import { AnyAction } from '@reduxjs/toolkit'
import { ThunkAction } from '@reduxjs/toolkit'
import { RootState } from './index'
import { pnsMnArrayModel, Data, Navigation } from '../models/redux-models';
import pnsMnService from '../service'

export const pnsMnActions:any = pnsMnSlice.actions

export const fetchpnsMns = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (dispatch, getState) => {
    if (getState().pnsMn.navigation.length === 0) {
      const response: Data = await pnsMnService.getAllData();
      dispatch(pnsMnActions.setpnsMn(response));
    }
  }
}

export const fetchPage = (
  slug?: string,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
      const response: any = await pnsMnService.GetPage(slug)
      dispatch(pnsMnActions.setAntPayPage(response.data))
    
  }
}

export const fetchNews = (
  slug?: string,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
      const response: any = await pnsMnService.GetNews(slug)
      dispatch(pnsMnActions.setNewsDetail(response.data))
    
  }
}

