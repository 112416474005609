import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "./components/Loader";
import { useAppDispatch, useAppSelector } from "./hooks/redux-hooks";
import { pnsMnStateModel } from "./models/redux-models";
import FaqsPage from "./pages/FaqsPage";
import Index from "./pages/Index";
import News from "./pages/News";
import Form from "./pages/Form";
import Page from "./pages/Page";
import ServicePage from "./pages/ServicePage";
import { fetchpnsMns } from "./store/Actions";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import FormThankYou from "./pages/FormThankYou";

function App() {
  const dispatch = useAppDispatch();
  const SiteSettings = useAppSelector(
    (state: pnsMnStateModel) => state.pnsMn?.site_settings
  );

  useEffect(() => {
    dispatch(fetchpnsMns());
  }, [dispatch]);

  if (!SiteSettings) {
    return <Loader />;
  }

  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route index element={<Index />} />
      <Route path="page" element={<Page SiteSettings={SiteSettings} />}>
        <Route path=":slug" element={<Page SiteSettings={SiteSettings} />} />
      </Route>
      <Route path="news" element={<News SiteSettings={SiteSettings} />}>
        <Route path=":slug" element={<News SiteSettings={SiteSettings} />} />
      </Route>
      <Route
        path="service"
        element={<ServicePage SiteSettings={SiteSettings} />}
      >
        <Route
          path=":id"
          element={<ServicePage SiteSettings={SiteSettings} />}
        />
      </Route>
      <Route
        path="formthanks"
        element={<FormThankYou SiteSettings={SiteSettings} />}
      ></Route>
      <Route
        path="faqs"
        element={<FaqsPage SiteSettings={SiteSettings} />}
      ></Route>
      <Route path="form" element={<Form SiteSettings={SiteSettings} />}></Route>
      <Route path="*" element={<Index />} />
    </Routes>
  );
}

export default App;
