import { Services, ServiceElements, HeightWidth } from '../models/redux-models'
import Loader from './Loader'
import { useNavigate } from 'react-router-dom'
import Icon, { ArrowRightOutlined } from '@ant-design/icons'
import { Button, Radio } from 'antd'

interface ServiceProps {
  services: Services[]
  heightWidth: HeightWidth
}

function CompService({ services, heightWidth }: ServiceProps) {
  const navigate = useNavigate()
  const navigateTo = (serviceId: any) => {
    navigate('/service/' + serviceId + '#service' + serviceId)
    window.scrollTo(0, 280)
  }

  if (!services) {
    return <Loader />
  }

  return (
    <div className="service-area">
      <div className="row spno service-row">
        {services &&
          services.map((service: Services, index: any) => (
            <div
              className="col wow bounceInUp"
              data-wow-duration="2s"
              data-wow-delay="0.2s"
              style={{
                visibility: 'visible',
                animationDuration: '2s',
                animationDelay: '0.2s',
                animationName: 'bounceInUp',
              }}
              key={index}
            >
              <div
                className="service-box onClick"
                onClick={() => navigateTo(service.id)}
              >
                <div className="media">
                  <img
                    src={
                      service.image
                        ? 'https://api.pns.mn/storage/' + service.image
                        : '/images/services/pic1.jpg'
                    }
                    alt=""
                  />
                </div>

                <div
                  className="info onClick"
                  onClick={() => navigateTo(service.id)}
                  style={{
                    minHeight: heightWidth.width < 700 ? 'auto' : 230,
                  }}
                >
                  <h4
                    className="   title onClick"
                    onClick={() => navigateTo(service.id)}
                  >
                    {service.name}
                  </h4>

                  <div
                    style={{
                      fontSize: 16,
                    }}
                  >
                    {service.elements &&
                      Object.values(service.elements).length > 0 && (
                        <ul>
                          {Object.values(service.elements).map(
                            (el: ServiceElements) => (
                              <li key={el.id}>{el.excerpt}</li>
                            ),
                          )}
                        </ul>
                      )}
                  </div>
                </div>

                <h4
                  className="btn "
                  onClick={() => navigateTo(service.id)}
                  style={{
                    textTransform: 'none',
                    fontSize: 14,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      marginRight: 6,
                    }}
                  >
                    Дэлгэрэнгүй
                  </span>
                  <ArrowRightOutlined />
                </h4>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
export default CompService
