import { Contacts } from '../models/redux-models'
interface Props {
  contacts: Contacts[]
  className: string
  type?: string
}
function ContactInfo({ contacts, className, type }: Props) {
  return (
    <ul className={className}>
      {contacts &&
        contacts.length > 0 &&
        contacts.map((contact: Contacts, index: any) => (
          <li key={index}>
            <i className={contact.icon} />
            {type === 'footer' ? (
              <>
                <strong>{contact.title} </strong> {contact.value}
              </>
            ) : (
              <>
                <h4 className="title">{contact.title}</h4>
                <p>{contact.value} </p>
              </>
            )}
          </li>
        ))}
    </ul>
  )
}

export default ContactInfo
