import { News } from '../models/redux-models'
interface pnsNewsProps {
  pnsNews: News[]
}

function NewsEvent({ pnsNews }: pnsNewsProps) {
  console.log(pnsNews)

  return (
    <div className="container">
      <div className="row">
        {pnsNews &&
          pnsNews.map((news: News, index: any) => (
            <NewsDetail key={index} news={news} />
          ))}
      </div>
    </div>
  )
}

export default NewsEvent

interface NewsDetailProps {
  news: News
}
export function NewsDetail({ news }: NewsDetailProps) {
  return (
    <div className="col-md-6 col-lg-4 col-sm-4 mb-5">
      <div className="dlab-card blog-grid">
        <div className="dlab-media pnews">
        <a href={'/news/' + news.slug}>
          <img
            src={
              news.image_cropped
                ? 'https://api.pns.mn/storage/' + news.image_cropped
                : 'images/blog/blog-grid/pic1.jpg'
            }
            alt={news.title}
          />
          </a>
        </div>
        <div className="dlab-info">
          <h3 className="dlab-title">
            <a href={'/news/' + news.slug}>{news.title}</a>
          </h3>
          <p>{news.excerpt}</p>
        </div>
      </div>
    </div>
  )
}
