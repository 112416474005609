import Logo from "../Logo.png";
import { Outlet, Link } from "react-router-dom";
import { Navigation } from "../models/redux-models";

interface NavigationProps {
  navigation?: Navigation[];
}

function Header({ navigation }: NavigationProps) {
  return (
    <>
      <header className="site-header header-transparent">
        <div className="sticky-header main-bar-wraper navbar-expand-lg">
          <div className="main-bar clearfix ">
            <div className="container-fluid clearfix">
              {/* website logo */}
              <div className="logo-header mostion logo-dark">
                <a href="/">
                  <img src="/images/logo.png" alt="" />
                </a>
              </div>
              {/* nav toggle button */}
              <button
                className="navbar-toggler collapsed navicon justify-content-end"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span />
                <span />
                <span />
              </button>

              {/* <div className="extra-nav">
                <div className="extra-cell">
                  <a href="#" className=" btn-outline-primary ">
                    =
                  </a>
                </div>
              </div> */}

              <div
                className="header-nav navbar-collapse collapse justify-content-end"
                id="navbarNavDropdown"
              >
                <div className="logo-header d-md-block d-lg-none">
                  <a href="/">
                    <img src="/images/logo.png" alt="" />
                  </a>
                </div>
                <ul className="nav navbar-nav navbar">
                  {navigation &&
                    Object.values(navigation).map(
                      (menu: Navigation, index: any) => (
                        <li key={index}>
                          <Link
                            className={"nav-link scrollto nav_id_" + menu.type}
                            to={menu.url}
                          >
                            {menu.title}
                          </Link>
                        </li>
                      )
                    )}
                  <li>
                    <Link className={"nav-link scrollto nav_id_"} to={"/form"}>
                      Захиалга өгөх
                    </Link>
                  </li>
                </ul>
                <div className="dlab-social-icon">
                  <ul>
                    <li>
                      <Link
                        className="site-button circle fa fa-facebook"
                        to={
                          "https://www.facebook.com/Professional-Nanny-Service-104712688355550"
                        }
                      ></Link>
                    </li>
                    <li>
                      <Link
                        className="site-button circle fa fa-instagram"
                        to="https://www.facebook.com/Professional-Nanny-Service-104712688355550"
                      ></Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* main header END */}
      </header>
    </>
  );
}

export default Header;
