import { SectionProps, Element } from '../modules/Main'

interface SProps {
  section: SectionProps;
  titleColor?: string;
}
function SectionTitle({ section, titleColor }: SProps) {
  const {
    big_title,
    description,
    button_text,
    button_url,
    section_key,
    section_color,
  }: SectionProps = section
  return (
    <div className={section_key + ' section-title'}>
      <div className="container">
        <div
          className="row align-items-end section-head wow fadeInUp"
          data-wow-duration="2s"
          data-wow-delay="0.5s"
          style={{
            visibility: 'visible',
            animationDuration: '2s',
            animationDelay: '0.5s',
            animationName: 'fadeInUp',
          }}
        >
          {big_title && big_title.length > 0 && (
            <div className="col-md-6">
              <h2 className="title" style={{
                color: titleColor ? titleColor : 'none'
              }}>{big_title}</h2>
              <div className={'dlab-separator bg-' + (section_color ? section_color : 'primary')} />
              {description && <p className="mb-3 mb-md-0">{description}</p>}
            </div>
          )}
          {button_text && button_url && (
            <div className="col-md-6 text-md-right">
              <a href={button_url} className={'btn btn-' + (section_color ? section_color : 'primary')}>
                {button_text}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default SectionTitle
