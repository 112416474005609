import { SiteSettings } from "../models/redux-models";
import Layout from "../Layouts/MainLayout";
import "../App.css";
import "../index.css";

import { useNavigate, useParams } from "react-router-dom";
import { PagesHeader } from "./Form";
import { Button } from "antd";
interface SiteSettingsProps {
  SiteSettings: SiteSettings;
}
function NewsComponent({ SiteSettings }: SiteSettingsProps) {
  let { slug } = useParams();
  const navigate = useNavigate();
  return (
    <Layout>
      {SiteSettings ? (
        <PagesHeader
          title={"Захиалга илгээсэнд баярллаа"}
          slug={""}
          pageheader_background_image={
            SiteSettings?.pageheader_background_image
          }
        />
      ) : (
        <></>
      )}

      <section className="content-inner-1">
        <div className="container">
          <div className="task-list">
            <h2>
              Бид таны өгсөн захиалгийн мэдээллийн дагуу эргэн холбогдох болно
            </h2>
            <Button onClick={() => navigate("/")}>Нүүр хуудасруу буцах</Button>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default NewsComponent;
