import * as React from "react";
import {
  SiteSettings,
  SettingsField,
  pnsMnStateModel,
} from "../models/redux-models";
import Layout from "../Layouts/MainLayout";
import "../App.css";
import "../index.css";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";

import { fetchNews } from "../store/Actions";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  BrowserRouter as Router,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Button, Checkbox, Input } from "antd";
import { Radio } from "antd";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { Formik, Field, useFormik } from "formik";
import * as Yup from "yup";
import { createOrder } from "../service";
interface SiteSettingsProps {
  SiteSettings: SiteSettings;
}
const { TextArea } = Input;

function NewsComponent({ SiteSettings }: SiteSettingsProps) {
  let { slug } = useParams();
  const navigate = useNavigate();

  const [value, setValue] = useState<any>();
  const [formTypeId, setformTypeId] = useState<number>(0);
  const [formTypeText, setformTypeText] = useState<string>("");
  const [formTasks, setFormTasks] = useState<any>();
  const [getTasksError, setTasksError] = useState<boolean>(false);

  const SignupSchema = Yup.object().shape({
    fullname: Yup.string().min(1, "Too Short!").required("Required"),
    phone: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      task: null,
      fullname: "",
      phone: "",
      email: "",
      address: "",
      talbai: "",
      with_family_member: "",
      requirement: "",
      token: "",
      type_form: "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values, error) => {
      if (formTasks && Object.values(formTasks).length > 0) {
        createOrder({
          ...values,
          type_form: formTypeText,
          tasks: formTasks,
        })
          .then((r: any) => navigate("/formthanks"))
          .catch((er: any) => console.log("errr", er));
      } else {
        setTasksError(true);
      }
    },
  });

  useEffect(() => {
    console.log("valuevalue", value);
  }, [value]);

  const onChange = (e: any) => {
    if (value && value[e]) {
      setValue({
        ...value,
        [e]: false,
      });
      setFormTasks({
        ...formTasks,
        [e]: false,
      });
    } else {
      setValue({
        ...value,
        [e]: true,
      });
    }
  };

  const onChange2 = (e: any, itemId: number, job_title: string) => {
    setFormTasks({
      ...formTasks,
      [itemId]: {
        item_id: itemId,
        job_title,
        timetable: e,
      },
    });
  };

  useEffect(() => {
    if (formTasks && Object.values(formTasks).length > 0) {
      setTasksError(false);
    }
  }, [formTasks]);

  const orderForms: any = useAppSelector(
    (state: pnsMnStateModel) => state?.pnsMn?.order_form
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchNews(slug));
  }, [dispatch, slug]);

  useEffect(() => {
    const aa: any = Object.values(orderForms);
    if (aa) {
      setformTypeId(Number(aa[0].type?.id));
      setformTypeText(aa[0]?.type?.title);
    }
  }, [orderForms]);

  const getOrderForms: any = useCallback(() => {
    const a = Object.values(orderForms).filter(
      (v: any) => Number(v?.type?.id) === Number(formTypeId)
    )[0];
    return a;
  }, [formTypeId, orderForms]);

  return (
    <Layout>
      {SiteSettings ? (
        <PagesHeader
          title={"Захиалга өгөх"}
          slug={""}
          pageheader_background_image={
            SiteSettings?.pageheader_background_image
          }
        />
      ) : (
        <></>
      )}

      <section className="content-inner-1">
        <div className="container">
          <div className="choose-btns">
            {orderForms &&
              Object.values(orderForms).map((orderForm: any) => (
                <Button
                  style={{
                    width: 240,
                    borderRadius: 10,
                  }}
                  key={orderForm?.type?.id}
                  type={
                    Number(orderForm?.type?.id) === Number(formTypeId)
                      ? "primary"
                      : "default"
                  }
                  onClick={() => {
                    setformTypeText(orderForm?.type?.title);
                    setformTypeId(Number(orderForm?.type?.id));
                    setFormTasks(null);
                    setValue(null);
                  }}
                  size={"large"}
                  icon={
                    Number(orderForm?.type?.id) === Number(formTypeId) ? (
                      <MdRadioButtonChecked
                        style={{
                          marginRight: 8,
                          fontSize: 20,
                        }}
                      />
                    ) : (
                      <MdRadioButtonUnchecked
                        style={{
                          marginRight: 8,
                          fontSize: 20,
                        }}
                      />
                    )
                  }
                >
                  {orderForm?.type?.title}
                </Button>
              ))}
          </div>

          <div className="task-list">
            <form onSubmit={formik.handleSubmit}>
              {getOrderForms()?.items &&
                getOrderForms()?.items.map((item: any) => (
                  <div
                    key={item?.item?.id}
                    className="task"
                    style={
                      getTasksError
                        ? {
                            borderLeft: "1px solid red",
                          }
                        : {}
                    }
                  >
                    <Checkbox
                      onChange={() => {
                        onChange(item?.item?.id);
                      }}
                    >
                      {item?.item?.job_title}
                    </Checkbox>
                    <div
                      style={{
                        padding: 10,
                        paddingLeft: 16,
                      }}
                    >
                      {item?.timetables && value && value[item?.item?.id] && (
                        <Radio.Group
                          onChange={(v) =>
                            onChange2(
                              v.target.value,
                              item?.item?.id,
                              item?.item?.job_title
                            )
                          }
                        >
                          {Object.values(item?.timetables).map(
                            (timetable: any, index: number) => (
                              <Radio
                                value={timetable?.title}
                                key={timetable?.timetable_id}
                              >
                                {timetable?.title}
                              </Radio>
                            )
                          )}
                        </Radio.Group>
                      )}
                    </div>
                  </div>
                ))}
              <div className="form-detail">
                <div className="item">
                  <h2
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Захиалагчийн мэдээлэл
                  </h2>
                </div>
                <input name="token" hidden />
                <div className="item">
                  <label>Овог, Нэр: </label>

                  <Input
                    type="text"
                    placeholder=""
                    name="fullname"
                    onChange={formik.handleChange}
                    value={formik.values.fullname}
                    required
                  />
                </div>
                <div className="item">
                  <label>Утас: </label>
                  <Input
                    type="text"
                    placeholder=""
                    name="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    required
                  />
                </div>
                <div className="item">
                  <label>И-Мэйл: </label>
                  <Input
                    type="text"
                    placeholder=""
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </div>
                <div className="item">
                  <label>Дэлгэрэнгүй хаяг: </label>
                  <TextArea
                    rows={4}
                    name={"address"}
                    onChange={formik.handleChange}
                    value={formik.values.address}
                  />
                </div>
                <div className="item">
                  <label>Талбайн хэмжээ /гэрийн/: </label>
                  <Input
                    type="text"
                    placeholder=""
                    name="talbai"
                    onChange={formik.handleChange}
                    value={formik.values.talbai}
                  />
                </div>

                <div className="item">
                  <label
                    style={{
                      marginRight: 10,
                    }}
                  >
                    Гэрийн эзэнтэй хамт байх эсэх:{" "}
                  </label>
                  <Radio.Group
                    name={"with_family_member"}
                    onChange={formik.handleChange}
                    value={formik.values.with_family_member}
                  >
                    <Radio value={"yes"}>Тийм</Radio>
                    <Radio value={"no"}>Үгүй</Radio>
                  </Radio.Group>
                </div>

                <div className="item">
                  <label>Тавигдах шаардлага: </label>
                  <TextArea
                    rows={4}
                    name={"requirement"}
                    onChange={formik.handleChange}
                    value={formik.values.requirement}
                  />
                </div>
                {formTasks && Object.values(formTasks).length > 0 ? (
                  <></>
                ) : (
                  <p
                    style={{
                      color: "red",
                      fontSize: 11,
                    }}
                  >
                    Ажлын жагсаалтаас аль нэгийг сонгоно уу{" "}
                  </p>
                )}
                <p
                  style={{
                    fontSize: 12.4,
                    lineHeight: "20px",
                  }}
                >
                  Захиалга баталгаажуулалтын төлбөр <b>100,000</b> төгрөгийг{" "}
                  <b>5107068939 </b>
                  Профессионал Нанни Сервис компанийн дансанд төлснөөр таны
                  захиалга баталгаажих болно. Та гүйлгээний утга дээр өөрийн
                  нэр, утасны дугаараа бичнэ үү.
                </p>
                <div className="btn-submit">
                  <Button
                    htmlType="submit"
                    type={"primary"}
                    onClick={() => {
                      console.log("dd");
                    }}
                    size={"large"}
                  >
                    {"Захиалга илгээх"}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default NewsComponent;

interface PageHeader {
  title?: string;
  slug: string;
  pageheader_background_image: SettingsField;
}
export function PagesHeader({
  title,
  slug,
  pageheader_background_image,
}: PageHeader) {
  return (
    <div className="dlab-bnr-inr">
      <div className="container">
        <div className="dlab-bnr-inr-entry">
          <h1> {title ? title : "Мэдээ, Мэдээлэл"}</h1>
        </div>
      </div>
    </div>
  );
}
