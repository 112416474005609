import { HomepageSectionAboutUs } from '../models/redux-models'
import { DangerouslySetInnerHTML } from '../Helper/Helpers'

interface HomepageAboutUsProps {
  homepageAboutUs: HomepageSectionAboutUs[]
}
function HomeAboutUs({ homepageAboutUs }: HomepageAboutUsProps) {
  const AboutUsSection: HomepageSectionAboutUs =
    homepageAboutUs && homepageAboutUs[0]

  return (
    <>
      <section className="section-full bg-white  about-bx1">
        {AboutUsSection ? (
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-12 wow fadeIn"
                data-wow-delay="0.4s"
                data-wow-duration="2s"
                style={{
                  visibility: 'visible',
                  animationDuration: '2s',
                  animationDelay: '0.4s',
                  animationName: 'fadeIn',
                }}
              >
                <div className="radius-sm rs-wave">
                  <img
                    src={'https://api.pns.mn/storage/' + AboutUsSection?.image1}
                    className="img-full"
                    alt=""
                  />
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-12 wow fadeIn"
                data-wow-delay="0.6s"
                data-wow-duration="2s"
                style={{
                  visibility: 'visible',
                  animationDuration: '2s',
                  animationDelay: '0.6s',
                  animationName: 'fadeIn',
                }}
              >
                <div className="section-head">
                  <h2 className="title" style={{
                    marginTop: 20
                  }}>
                    {AboutUsSection ? AboutUsSection?.title : ''}
                  </h2>
                  {AboutUsSection
                    ? DangerouslySetInnerHTML(AboutUsSection?.body)
                    : ''}
                </div>
                <a
                  href={AboutUsSection?.button_url}
                  className="btn btn-primary  m-b10"
                >
                  {AboutUsSection?.button_text}
                </a>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </section>
    </>
  )
}

export default HomeAboutUs
