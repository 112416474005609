import {
  pnsMnStateModel,
  SiteSettings,
  SettingsField,
} from '../models/redux-models'
import Layout from '../Layouts/MainLayout'
import '../App.css'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks'
import { fetchPage } from '../store/Actions'
import { useEffect } from 'react'
import { DangerouslySetInnerHTML, ImageStorageUrl } from '../Helper/Helpers'
import { BrowserRouter as Router, useParams } from 'react-router-dom'
import Loader from '../components/Loader'
import PageBody from '../components/PageBody'

interface SiteSettingsProps {
  SiteSettings: SiteSettings
}
function Page({
  SiteSettings
}: SiteSettingsProps) {
  let { slug } = useParams()
  const page = useAppSelector((state: pnsMnStateModel) => state.pnsMn?.page)

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchPage(slug))
  }, [slug])


  if (!page) {
    return <Loader />
  }

  return (
    <Layout>
      {SiteSettings ? (
        <PagesHeader
          title={page.title}
          slug={page.slug}
          pageheader_background_image={SiteSettings?.pageheader_background_image}
        />
      ) : (
        <></>
      )}
      <PageBody page={page} />
    </Layout>
  )
}

export default Page

interface PageHeader {
  title?: string
  slug: string
  pageheader_background_image: SettingsField
}
export function PagesHeader({ title, slug, pageheader_background_image }: PageHeader) {
  return (
    <div className="dlab-bnr-inr"
    >
      <div className="container">
        <div className="dlab-bnr-inr-entry">
          
          <h1> {title && title}</h1>
        </div>
      </div>
    </div>
  )
}
