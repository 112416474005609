import { pnsMnStateModel, SiteSettings } from '../models/redux-models'
import Layout from '../Layouts/MainLayout'
import '../App.css'
import { useAppSelector } from '../hooks/redux-hooks'
import { DangerouslySetInnerHTML } from '../Helper/Helpers'
import { PagesHeader } from './News'
import { Faqs } from '../models/redux-models'
import { Collapse } from 'antd'

interface SiteSettingsProps {
  SiteSettings: SiteSettings
}

function FaqsPage({ SiteSettings }: SiteSettingsProps) {
  const { Panel } = Collapse

  const faqs: Faqs[] = useAppSelector(
    (state: pnsMnStateModel) => state?.pnsMn?.faqs,
  )
  console.log(faqs)
  return (
    <Layout>
      {SiteSettings && (
        <PagesHeader
          title={'Түгээмэл асуулт хариулт'}
          slug={'@'}
          pageheader_background_image={
            SiteSettings?.pageheader_background_image
          }
        />
      )}

      <section
        className="innner"
        style={{
          marginTop: 40,
        }}
      >
        <div className="container">
          {faqs && (
            <Collapse defaultActiveKey={['0']}>
              {Object.values(faqs).length > 0 &&
                Object.values(faqs).map((faq: Faqs, indx: any) => (
                  <Panel header={faq.title} key={indx}>
                    {DangerouslySetInnerHTML(faq.text)}
                  </Panel>

                  // <div key={indx} className="clear m-b30" id="faqs-list">
                  //   <div className="faqss-area" id="faqss">
                  //     <ol className="faqs-list">
                  //       <li className="faqs">
                  //         <div className="faqs-body">
                  //           <div className="faqs-author ">
                  //               <b>{faq.title}</b>
                  //           </div>
                  //           <div className="faqs-content">
                  //             {DangerouslySetInnerHTML(faq.text)}
                  //           </div>
                  //         </div>
                  //       </li>
                  //     </ol>
                  //   </div>
                  // </div>
                ))}
            </Collapse>
          )}
        </div>
      </section>
    </Layout>
  )
}
export default FaqsPage
