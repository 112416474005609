import { Sliders } from '../models/redux-models'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

interface ServiceProps {
  Sliders: Sliders[]
}

function HeroSlider({ Sliders }: ServiceProps) {
  const [windowSize, setWindowSize] = useState<{
    width: any
    height: any
  }>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  if (!Sliders) {
    return <>Loading</>
  }
  return (
    <div>
      {Sliders && (
        <Carousel dynamicHeight={false} showArrows={true} showThumbs={false}>
          {Sliders.map((Slider: Sliders, index: any) => (
            <div
              key={index}
              style={{
                maxHeight: 900,
              }}
            >
              <p
                className="legend"
                style={{
                  bottom: windowSize.width < 780 ? '12px' : '142px',
                  background: 'none',
                  color: '#fff',
                  padding: '10px',
                  fontFamily: 'system-ui',
                  fontSize: windowSize.width < 700 ? '24px' : '44px',
                  lineHeight: windowSize.width < 700 ? '24px' : '48px',
                  textAlign: 'left',
                }}
              >
                <span>
                  {Slider.text1} <br />
                  <b
                    style={{
                      color: 'rgb(249 206 43)',
                      fontWeight: 'bold',
                    }}
                  >
                    {Slider.text2}
                  </b>
                </span>
              </p>

              {Number(windowSize.width) > 780 ? (
                <Link to={Slider.button_url} className="legend2 btn btn-primary">
                  Захиалга өгөх
                </Link>
              ) : (
                <></>
              )}

              <img
                style={{
                  marginTop: 80,
                }}
                alt={""}
                src={'https://api.pns.mn/storage/' + Slider.image}
              />
            </div>
          ))}
        </Carousel>
      )}
    </div>
  )
}

export default HeroSlider
 